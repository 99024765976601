<template>
  <div class="home">
    <!-- <div class="" @click="requestFullscreen()">点击全屏</div> -->
    <div class="iframeStyle">
      <iframe
        src="https://design-help.bj.yangwudongxi.com"
        style="width: 100%; height: 100%"
        frameborder="0"
        scrolling="yes"
        allowfullscreen="true"
        id="bdIframe"
      ></iframe>
    </div>
    <div class="footer">
      <footer-component></footer-component>
    </div>
  </div>
</template>

<script>
import Footer from './Footer.vue'
export default {
  name: '',
  data() {
    return {}
  },
  components: {
    'footer-component': Footer
  },
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style scoped>
.home {
  position: absolute;
  top: 0;
  left: 0;
  width: 1920px;
  height: 900px;
  background-color: rgba(0, 0, 0);
}
.iframeStyle {
  position: absolute;
  top: 100px;
  left: 0;
  width: 1920px;
  height: 800px;
}
.footer {
  position: absolute;
  top: 900px;
  left: 0;
  width: 1920px;
  height: 200px;
}
</style>
